import type { Schemas } from "#shopware";

export function useProductState(
  product: Schemas["Product"] | Ref<Schemas["Product"]>,
) {
  const _product = toRef(product);

  const isShipmentSpedi = computed(
    () => _product.value.customFields?.shipmentType == "spedi",
  );

  const isShipmentParcel = computed(
    () => _product.value.customFields?.shipmentType == "parcel",
  );

  const isBWare = computed(
    () => _product.value.customFields?.variantType === "bware",
  );

  const isBWare2 = computed(
    () => _product.value.customFields?.variantType === "bware2",
  );

  const isActionPrice = computed(
    () => _product.value.customFields?.isActionPrice,
  );

  const isBWarePickup = computed(
    () =>
      _product.value.productNumber.endsWith("-50") &&
      _product.value.customFields?.variantType === "bware",
  );

  const isNewWare = computed(
    () => _product.value.customFields?.variantType === "new",
  );

  const isSet = computed(
    () => _product.value.customFields?.variantType === "set",
  );

  const salesPercentage = computed<number | false>(() => {
    const percentage = +(
      _product.value.calculatedPrice?.listPrice?.percentage?.toFixed(0) ?? -1
    );
    return percentage > 0 ? percentage : false;
  });

  const isSellable = computed(() => !!_product.value.customFields?.isSellable);

  const hasAvailableStock = computed(
    () => (_product.value?.availableStock ?? 0) > 0,
  );

  const isReadyForSale = computed(
    () =>
      isSellable.value &&
      hasAvailableStock.value &&
      (_product.value?.calculatedPrice?.unitPrice ?? 0) > 0,
  );

  const inPartsSearch = computed(
    () => !!_product.value.customFields?.inPartsSearch,
  );

  const inMainSearch = computed(
    () => !!_product.value.customFields?.inMainSearch,
  );

  const hasSpareParts = computed(
    () => !!_product.value.customFields?.hasSpareParts,
  );

  const hasAccessories = computed(
    () => !!_product.value.customFields?.hasAccessories,
  );

  const isAccessory = computed(
    () => !!_product.value.customFields?.isAccessory,
  );

  const hasFiles = computed(() => _product.value.customFields?.mediabridge);

  return {
    isBWare,
    isBWare2,
    isBWarePickup,
    isActionPrice,
    isNewWare,
    isSet,
    salesPercentage,
    isSellable,
    hasAvailableStock,
    isReadyForSale,
    inPartsSearch,
    inMainSearch,
    hasSpareParts,
    hasAccessories,
    isAccessory,
    hasFiles,
    isShipmentSpedi,
    isShipmentParcel,
  };
}
